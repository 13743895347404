// SCSS based on this Doc

// https://dev.to/gedalyakrycer/ohsnap-sass-folder-structure-for-react-483e

// Screen sizes
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// colors
$primary-color: #faee;
$secondary-color: #faee;
$background-color: #fff;

$warning-color: #000;
$error-color: #eee;

$text-color-primary: #000;
$text-color-secondary: #000;