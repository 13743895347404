.react-tabs {
  &__tab {
    margin-bottom: 10px;
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-right: 40px;
    }
    & .dark-arrow {
      display: none;
    }
    &--selected {
      & .active {
        background-color: #f2f2f2;
        justify-content: center;
		box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 2px #e9e9e9;
		border: 3px solid transparent;
      }
      & .dark-arrow {
        display: inline-block;
      }
      & .light-arrow {
        display: none;
      }
    }
  }
}
