.react-datepicker__day {
  color: #000;
  width: 36px;
  height: 36px;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 0 0 1px 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
  border-radius: 0;
  background-color: #216ba5;
  color: #f1f1f1;
  &:hover {
    border-radius: 0;
  }
}

.react-datepicker__day--in-range {
  border-radius: 0;
  color: black;
  background-color: rgb(231, 245, 255);
  &:hover {
    border-radius: 0;
    background-color: rgb(231, 245, 255);
  }
}

.react-datepicker__day--range-start {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #3785f9;
  color: #f1f1f1;
  &:hover {
    background-color: #3785f9;
  }
}

.react-datepicker__day--range-end {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #3785f9;
  color: #f1f1f1;
  &:hover {
    background-color: #3785f9;
  }
}

.react-datepicker__day--selected {
  &.react-datepicker__day--selected {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #3785f9;
  }
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected) {
  background-color: rgb(231, 245, 255);
  color: black;
}

.react-datepicker__day--keyboard-selected {
  color: #f1f1f1;
  background-color: #3785f9;
  &:hover{
	background-color: #3785f9;
  }
}

.react-datepicker__day-name {
    color: rgb(173, 181, 189);
    display: inline-flex;
    width: 36px;
    height: 36px;
    line-height: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 0 1px 0;
}

.react-datepicker__header {
    text-align: center;
    background-color: #fff;
    border-bottom: 0;
    border-top-left-radius: 0;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0;
}

.react-datepicker {
    background-color: #fff;
    border: 0;
    border-radius: 0;
    display: inline-block;
    position: relative;
}

.react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
}
