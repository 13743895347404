.mod-toolbar {
    border-radius: 12px 12px 0px 0px;
    border: 2px solid #ccc;
    border-bottom: none;
    margin-bottom: 0;
}
  
.mod-editor {
    background: #f4f4f4;
    height: 200px;
    border-radius: 0px 0px 12px 12px;
    border: 2px solid #ccc;
    border-top: none;
    padding: 12px;
}

.rdw-editor-main {
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-top: 0px;
}
.rdw-editor-main::-webkit-scrollbar {
    display: none;
}

.rdw-block-wrapper,
.rdw-fontsize-wrapper,
.rdw-colorpicker-wrapper,
.rdw-link-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper,
.rdw-fontfamily-wrapper{
    display: none;
}

.rdw-option-wrapper {
    padding: 10px;
}

.rdw-option-active,
.rdw-option-active:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
    background: beige !important;
}
