@font-face {
    font-family: 'Fellix';
    src: url('Fellix-SemiBold.eot');
    src: local('Fellix SemiBold'), local('Fellix-SemiBold'),
        url('Fellix-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Fellix-SemiBold.woff2') format('woff2'),
        url('Fellix-SemiBold.woff') format('woff'),
        url('Fellix-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}