.jobs-loader__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0rem;
}
.jobs-loader-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid green;
    // padding: 8px 16px;
    background: #FCFCFC;
    border: 2px solid #EFEFEF;
    border-radius: 20px 4px;
    width: 135px;
    height: 40px;
}
.jobs-loader__spinner {
    height: 35px;
    width: 35px;
}
.jobs-loader__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #1A1D1F;
}